import { CUSTOM_BRAND_ID } from '../../../constants/pointsExchange'

const filterRewardTypesForExchange = ({
  redemptionOptions,
  exchangeMemberships
}) => {
  if (exchangeMemberships) {
    const isTrollBeadsMembershipFound =
      exchangeMemberships[CUSTOM_BRAND_ID.TROLLBEADS_MEMBERSHIP_CLUB]
    if (
      isTrollBeadsMembershipFound &&
      isTrollBeadsMembershipFound.membershipNumber
    ) {
      return redemptionOptions.filter(
        (rewardType) => rewardType?.metadata?.showOnlyForClubMembers
      )
    } else {
      return redemptionOptions.filter(
        (rewardType) => !rewardType?.metadata?.showOnlyForClubMembers
      )
    }
  } else {
    return []
  }
}

export default filterRewardTypesForExchange
