import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'

import HowManyPoints from '../../../components/welcome/HowManyPoints'
import { BWOutsideLink, BWLink } from 'src/libs/qb-brand-web-components'
import { MANAGE_ACCOUNTS_URL } from '../../../constants/navigation'
import brandConfig from 'brandConfig'

const HowToEarnRewardsWrapper = ({ t, tokenSymbol }) => {
  const labelArgs = { point: 25, tokenSymbol }

  return (
    <>
      <HowManyPoints
        data={[
          { i18nKey: 'brand:earn_rewards.label1', point: 1, tokenSymbol },
          {
            i18nKey: 'brand:earn_rewards.label2',
            url: t('brand:earn_rewards.event'),
            to: brandConfig.eventUrl,
            ...labelArgs
          },
          { i18nKey: 'brand:earn_rewards.label6', point: 2, tokenSymbol },
          { i18nKey: 'brand:earn_rewards.label3', ...labelArgs },
          {
            i18nKey: 'brand:earn_rewards.label4',
            url: t('brand:earn_rewards.facebook'),
            to: brandConfig.fbUrl,
            ...labelArgs
          },
          {
            i18nKey: 'brand:earn_rewards.label5',
            url: t('brand:earn_rewards.instagram'),
            to: brandConfig.instaUrl,
            ...labelArgs
          }
        ]}
        socialMedias
      />
      <CustomPanelContent>
        <Trans i18nKey='brand:earn_rewards.text' i18nIsDynamicList>
          <BWOutsideLink
            to='https://www.trollbeadsclub.it/come-iscriversi/'
            label={t('brand:earn_rewards.trollbeads-club')}
          />
          <BWLink
            label={t('brand:earn_rewards.your-account')}
            to={MANAGE_ACCOUNTS_URL}
            as={Link}
          />
        </Trans>
      </CustomPanelContent>
    </>
  )
}

export default memo(HowToEarnRewardsWrapper)

const CustomPanelContent = styled.div`
  text-align: center;
`
