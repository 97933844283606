import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BWOutsideLink } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import OptionalAndMandatoryCheckboxes from '../../../components/shared/optionalAndMandatoryCheckboxes/OptionalAndMandatoryCheckboxes'
import OptionalCheckbox from '../../../components/shared/optionalCheckbox/OptionalCheckbox'
import { SIGNUP_PROFILE_DISPATCH } from '../../../store/reducers/auth/signup'

const TermsNotAcceptedWrapper = ({
  signupProfile,
  onCancelTerms,
  onAcceptTermsSubmit,
  dispatchActionSignup
}) => {
  const { t } = useTranslation()
  const { error, mandatoryCheckbox } = signupProfile
  return (
    <OptionalAndMandatoryCheckboxes
      signupProfile={signupProfile}
      onCancelTerms={onCancelTerms}
      onAcceptTermsSubmit={onAcceptTermsSubmit}
      dispatchActionSignup={dispatchActionSignup}
    >
      {brandConfig.features.enableMandatoryCheckbox && (
        <OptionalCheckbox
          dispatchAction={dispatchActionSignup}
          error={error}
          checkbox={mandatoryCheckbox}
          description={
            <Trans i18nKey='brand:common.mandatory-checkbox' i18nIsDynamicList>
              <BWOutsideLink
                label={t('brand:common.present-information')}
                to={brandConfig.termsAndPrivacyUrl}
              />
            </Trans>
          }
          dispatchCheckbox={SIGNUP_PROFILE_DISPATCH.MANDATORY_CHECKBOX}
        />
      )}
    </OptionalAndMandatoryCheckboxes>
  )
}

export default memo(TermsNotAcceptedWrapper)
