import {
  deepMerge,
  CSS_TEXT_TRANSFORM_NONE
} from 'src/libs/qb-brand-web-components'

import defaultConfig from '../common/customConfig'

const allYourTransactionsLabel = 'brand:transactions.all-your-transactions'
const customConfig = {
  pointsLabel: 'brand:common.points',
  connectedAccountsLabel: 'brand:exchange.connected-accounts',
  allTransactionsLabel: allYourTransactionsLabel,
  allYourTransactionsLabel,
  loginPageTitle: 'brand:login.log-in',
  termsAcceptPageTitle: 'common.terms-conditions',
  rewardFromCodeFailedDesc: 'brand:error.points-from-code-failed',
  howToEarnLabel: 'brand:reward.how-to-earn-rewards',
  receiveYourRewardLabel: 'brand:reward.receive-your-reward',
  enterRewardCodeLabel: 'brand:reward.enter-reward-code',
  overviewEnterRewardCodeLabel: 'brand:reward.enter-reward-code',
  receiveRewardLabel: 'brand:reward.receive-reward',
  howManyPointsYouEarnLabel: 'brand:reward.how-many-points-you-earn',
  youEarnedItTitle: 'brand:overview.you-earned-it',
  receivedPointsDesc: 'brand:message.received-points',
  receivedPointsButtonLabel: 'brand:common.receive-points',
  receiveConfirmDesc:
    'brand:message.you-will-receive-following-amount-of-points',
  pointsOverviewLabel: 'brand:nav-header.points-overview',
  redeemPointsLinkLabel: 'brand:redeem.redeem-points',
  redeemPointsLabel: 'brand:redeem.redeem-points',
  redeemPointsLinkCss: CSS_TEXT_TRANSFORM_NONE,
  allTransactionsLabelCss: CSS_TEXT_TRANSFORM_NONE,
  manageAccounts: 'brand:exchange.manage-accounts',
  manageConnectedAccounts: 'brand:exchange.manage-connected-accounts',
  connectAccount: 'brand:exchange.connect-account'
}

export default deepMerge(defaultConfig, customConfig)
